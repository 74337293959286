.container-title{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50px) translateY(-151px);
    z-index: 1;
    animation: opacity 1s;
}
.container-title p{
    font-family: var(--font2);
    margin:0;
    font-size: 38px;
   
    opacity: 0.3;
    transform: translateX(-30px) translateY(-10px) rotateZ(-7deg);
}
.container-title h1{
    font-family: var(--font1);
    margin:0;
    font-size: 140px;
    letter-spacing: 3px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    font-weight: 400;
   
}
.container-title h2,a{
    font-family: var(--font2);
    margin:0;
    font-size: 38px;
    color: var(--color3);
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    text-decoration: none;
    text-align: start;
   
}
.container-title h2{
    transform: translateX(350px) translateY(-15px);
    
}
/* Desktop */
@media (min-width: 1400px) {
    .container-title{
 
        transform: translateX(50px) translateY(-151px) scale(1.2);
      
    }
   
}
@media (max-width: 1100px) {
    .container-title{
 
        transform: translateX(230px) translateY(-151px) scale(.9);
      
    }
   
}
@media (max-width: 700px) {
    .container-title{
 
        transform: translateX(270px) translateY(-151px) scale(.65);
      
    }
}
@media (max-width: 500px) {
    .container-title{
 
        transform: translateX(300px) translateY(-151px) scale(.5);
      
    }
}
@media (max-width: 300px) {
    .container-title{
 
        transform: translateX(300px) translateY(-151px) scale(.4);
      
    }
}

/* Animations */
@keyframes opacity {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;

    }
}


