.container-build{
    margin: 0;
    
}
.container-build h1{
    position: relative;
    margin: 0;
    top: 200px;
    text-transform: uppercase;
    font-family: var(--font1);
    text-align: center;
    color: var(--color3);
}
.build-img{
    position: absolute;
    bottom: 0;
    right: 0;
    
}

