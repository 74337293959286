.about-container{
    position: absolute;
    top: 20%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-text{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
}
.about-profession{
    animation: upTexts 3s;
}
.about-me{
    animation: upTexts 4s;
}
.about-study{
    animation: upTexts 5s;
}

.about-titles{
    display: flex;
    align-items: center;
}

.about-titles h2{
    font-family: var(--font1);
    color: var(--color4);
    font-size: 30px;
    margin: 0 15px;
    letter-spacing: 1.5px;

}

.about-text p{
    font-family: var(--font2);
    color: var(--color4);
    font-size: 18px;
    opacity: .6;
    width: 90%;
}

@keyframes upTexts {
    0%{
        transform: translateY(-500px);
    }100%{
        transform: translateY(0px);

    }
}