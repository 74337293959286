.watch-container{
    width: 80%;
    margin: 50px auto 0;
}
.watch-title {
    display: flex;
    align-items: flex-end;
}
.watch-title h2{
    font-family: var(--font1);
    color: var(--color4);
    font-size: 30px;
    margin: 0px 15px;
    letter-spacing: 1.5px;

}
.watch-time{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin-top: 30px;
}
.watch-time div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 17px;
    border: 6px solid var(--color3);
    border-radius: 15%;
    width: 5%;
}
.watch-number h3{
    margin: 0;
    font-size: 40px;
    color: var(--color4);
    font-family: var(--font1);
    letter-spacing: 2px;
    text-align: center;
}
.watch-time h4{
    font-size: 18px;
    font-family: var(--font2);
    color: var(--color4);
    opacity: 0.5;
    margin: 0;
}
.watch-title {
    animation: leftWatch 6.4s;
}
.watch-time div:nth-child(1){
    animation: upWatch 6s ;
}
.watch-time div:nth-child(2){
    animation: upWatch 5s;
}
.watch-time div:nth-child(3){
    animation: upWatch 4s;
}
.watch-time div:nth-child(4){
    animation: upWatch 3s;
}
.watch-time div:nth-child(5){
    animation: upWatch 2s;
}
.watch-time div:nth-child(6){
    animation: upWatch 1s;
}


@keyframes upWatch {
    0%{
       transform: translateY(500px); 
    }70%{
        transform: translateY(-100px); 
    }100%{
        transform: translateY(0px); 
    }
}

@keyframes leftWatch {
    0%{
       transform: translateX(700px); 
    }70%{
        transform: translateX(-300px); 
    }100%{
        transform: translateX(0px); 
    }
}


