.container-media,.media-menu{
    position: absolute;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
}
.container-media svg{
    margin-top: 8px;
    width: 39px;
    height: 40px;
    position: relative;

}

.container-media svg:hover{
    margin-top: 8px;
    transform: scale(1.1);
    cursor: pointer;
}


@media (max-width: 600px){
    .container-media{
        display: none;
    }
    .media-menu{
        position: absolute;
        bottom: 2%;
        right: 5%;
        z-index: 4;
        display: flex;
        flex-direction: row;
        transform: scale(1.3);
        align-items: center;
        animation: socialAnimate 2s;
    }

    .media-menu svg{
      margin: 0 10px;
    }
}


@keyframes socialAnimate {
    0%{
        transform: translateX(500px)  scale(1.3);
    }100%{
        transform: translateX(0px)  scale(1.3);
    }
}