.skill2-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 7%;
    left: 10%;
    animation: skill1Enter 2s;
}
.skill2-text{
    width: 40%;
}
.skill2-text h2{
    color: var(--color4);
    font-family: var(--font1);
    font-size: 43px;
    letter-spacing: 2.5px;
    font-weight: 400;

}
.skill2-text p{
    color: var(--color4);
    opacity: .3;
    font-family: var(--font2);
    font-size: 20px;
    letter-spacing: 1px;
}
.skill2-icons-container{
    display: flex;
    justify-content: space-around;
    width: 100%;
   
}

.skill2-icons-container svg{
    width: 70px;
    height: 70px;
}
.skill2-video{
  width: 50%;

}
.gif-phones{
  width: 65%;
}