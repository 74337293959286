.nav,.nav-burger{
    position: absolute;
    right: 0;
    top: 23px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
}

.nav svg,.nav-burger svg{
    display: none; 
    cursor: pointer;
}
.nav p,.nav-burger p{
    color: var(--color4);
    text-decoration: none;
    margin-left: 27px;
    opacity: 0.5;
    font-size: 14px;
    font-family: var(--font2);
   letter-spacing: 1px;
}
.nav  p:hover{
    color: var(--color3);
    transform: scale(1.1);
    opacity: 0.7;

    cursor: pointer;
    
}



/* Media */

@media (max-width: 600px) {
    .nav svg{
        display: block;
        margin-right: 30px;
        margin-top: 10px;
    }
    .nav p{
        display: none;

    }
    .nav-burger{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 4;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        background: var(--color3) ;
        padding: 40px  15px 0 30px;
        animation: openMenu 2s;
    }
   
    .nav-burger svg{
        display: block;
        cursor: pointer;
       
    }
    .nav-burger p{
        text-align: start;
        font-size: 20px;
        cursor: pointer;
        opacity: 1;
        color: red;

        
    }
    
}



/* Animations */

@keyframes openMenu {
    0%{
       
        transform: translate(400px);
    }100%{
        transform: translate(0px);

    }
}