.nav-pages{
    display: flex;
    position: absolute;
    bottom: 5%;
    right: 48%;
}

.nav-pages p{
    font-family: var(--font1);
    color: var(--color4);
    cursor: pointer;
    font-size: 30px;
    margin: 0 20px;
    opacity: .5;
}


.nav-pages p:hover{
    transform: scale(1.5);
}