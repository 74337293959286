.skill1-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 23%;
    left: 10%;
    width: 80%;
}
.skill1-video {
    width:60%;
    height: 100%;
}
/*gifs*/
.gif-pc{
    width: 48%;
    position: absolute;
    top: 0%;
    left: 3%;
    z-index: 1;
    cursor: pointer;
    animation: 4s upDesktop;
}
.gif-lap{
    width: 35%;
    position: absolute;
    top: 52%;
    left: -7%;
    z-index: 2;
    cursor: pointer;
    animation: 3s leftDesktop;
}
.gif-tablet{
    width: 10%;
    position: absolute;
    z-index: 2;
    left: 40%;
    top: 50%;
    cursor: pointer;
    animation: 3s downDesktop;
}
.gif-phone{
    width: 6%;
    position: absolute;
    z-index: 3;
    top: 70%;
    left: 47%;
    cursor: pointer;
    animation: 4s downDesktop;

}
.skill1-text {
    width: 40%;
}
.skill1-text h2{
    font-size: 40px;
    font-family: var(--font1);
    color: var(--color4);
    font-weight: 400;
    letter-spacing: 1px;
}
.skill1-text p{
    font-size: 18px;
    font-family: var(--font2);
    color: var(--color4);
    opacity: .2;
    line-height: 25px;
  
}
.skill1-icons-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.skill1-icons-container svg{
    width: 70px;
    height: 70px;
    margin-top: 30px;
    
}


@keyframes skill1Enter {
    0%{
        transform:  scale(0);

        opacity: .0;
    }100%{
        opacity: 1;
        transform:  scale(1);
    }
}

@keyframes skill1Exit {
    0%{
        transform: translateX(0px) scale(1);
        opacity: 1;
    }100%{
        transform: translateX(2000px) scale(0);
        opacity: .1;
    }
}

@keyframes upDesktop {
    0%{
        transform:  scale(0);
        opacity: .1;
    }100%{
        transform:  scale(1);
        opacity: 1;
      }
}

@keyframes leftDesktop {
    0%{
        transform: translateX(-1000px) ;
    }100%{
        transform: translateX(0px);
    }
}
@keyframes downDesktop {
    0%{
        transform: translateY(1000px) ;
    }100%{
        transform: translateY(0px);
    }
}



