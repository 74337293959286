
:root{
    --gradiant: linear-gradient(108.35deg, #EEEEEE 68.87%, #FFFFFF 102.27%);
    --gradiantDark:linear-gradient(91.38deg, #222831 1.14%, rgba(0, 0, 0, 0.94) 98.96%);
    --color1: #222831;
    --color2: #393E46;
    --color3: #00ADB5;
    --color4: #EEEEEE;
    --font1:  'Alfa Slab One', cursive;
    --font2:  'Sen', sans-serif;
    --font3:  'Great Vibes', cursive;  
}


html,body{
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#root{
    background: var(--gradiantDark);
    width: 100%;
    height: 100%;
    animation: 51s changeBg  linear;
}
