.skill3-container{
    display: flex;
    position: absolute;
    justify-content:space-around;
    align-items: center;
    top: 20%;
    left: 10%;
    animation: skill1Enter 2s;

}
.skill3-video{
    display: flex;
    align-items: center ;
    flex-direction: column;
    width: 25%;
}
.skill3-video img{
 width: 100%;

}
.skill3-text{
    width: 40%;
 
}

.skill3-types{
    display: flex;

}
.skill3-types h2{
    display: flex;
    cursor: pointer;
    color: var(--color4);
    font-family: var(--font1);
    margin-right: 20px;
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 400;

}
.skill3-skills{
    display: flex;
}

.skill3-skills p{
    border: solid 1px var(--color4);
    color: var(--color4);
    padding: 5px;
    cursor: pointer;
    margin-right: 10px;
    font-family: var(--font2);
    font-size: 18px;
}