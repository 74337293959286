.contact-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-form {
    display: flex;
    width: 80%;
    height: 20%;
}
.contact-form label{
    font-family: var(--font1);
    color: var(--color4);
    font-size: 36px;
    letter-spacing: 2px;
}
.contact-form input,textarea{
    background: none;
    border: 2px solid var(--color2);
    border-radius: 5px;
    outline: none;
    color: var(--color4);
    font-size: 30px;
    padding: 10px 5px;
    font-family: var(--font2);
    margin-bottom: 20px;
}
.contact-container input:hover,textarea:hover{
    border-color: var(--color3 );
}
.contact-form div{
    width: 50%;
    height: 100%;
}
.contact-form div{
    display: flex;
    flex-direction: column;
}
.contact-section2,.contact-section1{
    align-items: center;
}
.contact-section2 button{
    background-color: var(--color3);
    border-radius: 5px;
    font-size: 30px;
    border: none;
    padding: 10px 5px;
    width: 50%;
}