.web369-container { 
    width: 100%;
    height: 100%;
}
.web369-container h1 { 
    position: absolute;
    top: 10%;
    left: 40%;
    font-size: 60px;
    color: var(--color3);
    font-family: var(--font1);
    
}

.web369-text{
    position: absolute;
    top: 25%;
    left: 35%;
    text-align: center;
    color: var(--color4);
    font-weight:var(--font2);
    justify-content: center;
}
.web369-text p{
    width: 50%;
    font-size: 22px;
    opacity: .7;
}
.web369-text h3{
    width: 50%;
}
.web369-text svg{
    align-self: center;

}
.gif-369{
    position: absolute;
    bottom: 6%;
    left: 0;
    width: 100%;
    cursor: pointer;
}