.container-home{
    width: 92%;
    height: 100%;
    max-height:max-content;
    margin: 0 auto;
    position: relative;

}
.logo{
    width: 76px;
    height: 64px;
    position: absolute;
    left: 0;
    top: 23px;
}
.logo:hover{
    cursor: pointer;
    opacity: .5;
}
.face{
    height: 100% ;
    width: 60%;
    opacity: .1;
    position: absolute;
    right: -5%;
    z-index: 0;
}
.sign-footer{
    font-family: var(--font3);
    color: var(--color4);
    font-size: 45px;
    text-align: center;
    margin: 0;
    opacity: 0.5;
    letter-spacing: 2px;
    font-weight: 400;
}
@media (max-width: 800px){
  
    .face{
        width: 100%;
        right: -15%;

    }
}

@media (max-width: 600px){
    .container-home{
        width: 100%;
    }
    .logo{
        left: 23px;
        top: 23px;
    }
    .face{
        width: 100%;
        right: -30%;

    }
}
@media (max-width: 500px){

    .face{
        width: 100%;
        right: -35%;

    }
}


