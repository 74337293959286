.nav-portafolio{
    position: absolute;
    right: 0;
    top: 45%;
}
.nav-portafolio > div{
    width: 20px;
    height: 20px;
    background-color: var(--color4);
    border-radius: 50%;
    cursor: pointer;
    opacity: .4;
    margin: 10px 0;
}
.nav-portafolio > div:hover{
    
    opacity: .7;
}